import { useEffect, useState } from "react";
import colors from "../../../../../colors.js";
import Flex from "../../../../global/Flex";
import TextField from '../../../../global/TextField.js';
import Select from '../../../../global/Select.js';

const errorMessages = {
  linkRequired: '* Surgeon link required to resolve location',
  noEnterpriseLink: '* Surgeon link is incompatible with non enterprise scope',
  onlyPersonalPersonal: '* Personal access is only compatible with personal scope'
}


export default function({ organization, newUser, setSaveStatus, linkableSurgeons }) {
  const [error, setError] = useState({ linkRequired: '' });

  useEffect(() => {
    setSaveStatus('disabled');
    const { role_access, role_scope, surgeon_id } = newUser.value;
    if ((role_access === 'personal' || role_scope !== 'enterprise') && !surgeon_id) return setError(prev => ({ ...prev, linkRequired: errorMessages.linkRequired }));
    else if (role_scope === 'enterprise' && surgeon_id) return setError(prev => ({ ...prev, linkRequired: errorMessages.noEnterpriseLink }));
    else if (role_access === 'personal' && role_scope !== 'personal') return setError(prev => ({ ...prev, linkRequired: errorMessages.onlyPersonalPersonal }));
    else setError({ linkRequired: '' });
    if (newUser.value.email && newUser.value.name) setSaveStatus('active');
  }, [newUser.value]);

  if (organization?.organization_identifier === 'admin') return <Flex column f={1} alss>Admin account creation is currently disabled</Flex>
  return (
    <Flex column f={1} alss>
      <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Create User</Flex>
      <Flex style={{ marginBottom: 20 }} />
      <Flex column alss style={{ margin: 5 }}>

        {/* USER INFO */}
        <Flex alss>
          <TextField disabled label='Organization' value={organization.organization_identifier} />
        </Flex>
        <Flex alss>
          <TextField label='Name' value={newUser.value.name} onChange={({ target }) => newUser.set({ name: target.value })} />
          <TextField label='Email' value={newUser.value.email} onChange={({ target }) => newUser.set({ email: target.value })} />
        </Flex>

        <Flex style={{ marginBottom: 20 }} />

        {/* LINK SURGEON */}
        <Flex column alss>
          <Flex jcsb alss aibl>
            <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Link Surgeon Data</Flex>
            <div style={{ color: colors.red, fontStyle: 'italic', fontSize: 10}}>{error.linkRequired}</div>
          </Flex>
          <Flex style={{ marginBottom: 20 }} />
          <Flex alss aic>
            <Select
              width={240}
              value={newUser.value.surgeon_id || ''}
              onChange={({ target }) => {
                newUser.set({ surgeon_id: target.value })
              }}
              label='Linkable Surgeons'
              options={linkableSurgeons.map(({ id, name, npi }) => ({ value: id, label: `${name} - ${npi}`}))}
            />
            <Flex style={{ marginLeft: 10 }} />
            {/* <div style={{ color: colors.secondary, fontWeight: 'bold', padding: 10, cursor: 'pointer' }}>UNLINK</div> */}
          </Flex>
        </Flex>


          <Flex style={{ marginBottom: 20 }} />


          {/* ROLES PERMISSIONS */}
          <Flex column style={{ }}>
            <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Roles/Permissions</Flex>
            <Flex style={{ marginBottom: 20 }} />
            <Flex column style={{ fontSize: 10, color: colors.offwhite, fontStyle: 'italic' }}>
              <div style={{ marginBottom: 3 }}>With the below permissions, user can:</div>
              <div style={{ marginLeft: 10, marginBottom: 3 }}>- View data outside their personal zone</div>
              <div style={{ marginLeft: 10, marginBottom: 3 }}>- Can access high permission features (ex: Surgeon v Surgeon comparison)</div>
            </Flex>
            <Flex style={{ marginBottom: 20 }} />
            <Flex alss>
              <Select 
                value={newUser.value.role_access || ''}
                onChange={({ target }) => newUser.set({ role_access: target.value })}
                label='Access'
                options={[
                  { value: 'personal', label: 'Personal' },
                  { value: 'global', label: 'Global' },
                ]}
              />
              <Flex style={{ marginRight: 20 }} />
              <Select 
                value={newUser.value.role_scope || ''}
                onChange={({ target }) => newUser.set({ role_scope: target.value })}
                label='Scope'
                options={[
                  { value: 'personal', label: 'Personal' },
                  { value: 'hospital', label: 'Hospital' },
                  { value: 'state', label: 'State' },
                  { value: 'enterprise', label: 'Enterprise' }
                ]}
              />
            </Flex>
          </Flex>
      </Flex>
    </Flex>
  )
}

