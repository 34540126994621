import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Tooltip } from '@mui/material';
import Flex from '../global/Flex.js';
import { visuallyHidden } from '@mui/utils';
import colors from '../../colors.js';


export default function({ rows = [], columns = [], selected, handleSelection }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState([]);
  const [emptyRows, setEmptyRows] = useState([]);

  useEffect(() => {
    // Avoid a layout jump when reaching the last page with empty rows.
    const empty = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(empty);
    const comparator = getComparator(order, orderBy);
    const visRows = stableSort(rows, comparator).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setVisibleRows(visRows);
  }, [rows, order, orderBy, page, rowsPerPage]);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (!a || !b) {
      console.error('Invalid arguments', { a, b, orderBy });
      return 0;
    }
  
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
      <Flex alss style={{ paddingLeft: 40 }}>
        <Box sx={{ width: '100%' }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow 
                      key={index}
                      hover 
                      tabIndex={-1} 
                      key={index} 
                      sx={{ 
                        cursor: 'pointer', 
                        '&:hover': colors.lightest, 
                        borderLeft: `3px solid ${selected?.id === row.id ? colors.secondary : colors.light}`, 
                        borderTop: `1px solid ${colors.light}` 
                      }} 
                      onClick={() => handleSelection(row)}
                    >
                      {columns.map(({ id }, i) => (
                        <TableCell 
                          key={i}
                          style={{ 
                            fontSize: 14, 
                            borderBottom: `1px solid ${colors.light}`, 
                            color: colors.white, 
                            padding: 5, 
                            paddingLeft: 15 
                          }} 
                          component="th" 
                          id={labelId} 
                          scope="row" 
                          padding="none"
                        >
                          {row[id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ color: colors.white }}
            />
        </Box>
      </Flex>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };
  return (
    <TableHead>
      <TableRow>
        {props.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ borderBottom: `1px solid ${colors.light}` }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ position: 'relative', color: colors.grey }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
    <Typography
      sx={{ flex: '1 1 100%' }}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      {props.procedure_name}
    </Typography>
    </Toolbar>
  );
}
