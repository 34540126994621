import Flex from "./global/Flex";
import colors from "../colors";

const types = {
  success: colors.green,
  warning: colors.secondary,
  failure: colors.red
}

export default function ({ dimensions = {}, notification = {} }) {
  if (notification?.text && !types[notification?.type]) throw Error(`Invalid use of notification types: ${notification?.type}`);
  return (
    <Flex aic jcc style={{ 
      zIndex: 98,
      height: 30, 
      color: colors.darkest,
      fontSize: 16,
      fontWeight: 'bold',
      backgroundColor: types[notification?.type],
      position: 'absolute',
      top: dimensions?.height_header + 21,
      left: dimensions?.width_navigation,
      right: 0,
      left: 0,
      transform: notification?.text ? 'translateY(0px)' : 'translateY(-100px)',
      transition: 'transform 0.2s ease-in-out'
    }}>{notification?.text}</Flex>
  )
}
