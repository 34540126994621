export function getSession() {
  return {
    organization_identifier: localStorage.getItem('organization_identifier'),
    user_identifier: localStorage.getItem('user_identifier'),
    portal: localStorage.getItem('portal')
  }
}

export function setSession({ 
    organization_identifier, 
    user_identifier, 
    portal,
    access_token,
    refresh_token
  }) {
  localStorage.setItem('organization_identifier', organization_identifier);
  localStorage.setItem('user_identifier', user_identifier);
  localStorage.setItem('portal', portal);
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);
}

export function closeSession() {
  localStorage.clear();
}