import { useState, useRef } from 'react';
import colors from '../colors.js';
import Flex from "./global/Flex.js";
import { Fab, Popover, MenuItem } from '@mui/material';
import logo from '../assets/logo.png';
import logoRound from '../assets/logo-round.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Notification from './Notification.js';

export default function({ username, dimensions, handleLogout, notification }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const fabRef = useRef(null);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Flex alss jcsb style={{ height: dimensions.height_header, ...styles.container }}>
        <Flex aic jcc>
          <img src={logo} style={{ height: 'auto', width: 60 }} alt="Logo" />
          <Flex column alss afs jcc style={{ fontWeight: 'bold', fontSize: 18 }}>
            <div>Internal</div>
            <div>Admin</div>
          </Flex>
        </Flex>
        <Flex alsfe jcsb>
          <Fab 
            ref={fabRef}
            onClick={handleMenuOpen}
            style={styles.profileButton} 
            aria-label="add"
            >
            <img src={logoRound} style={{ height: 'auto', width: 40, transform: 'translateX(-3px)' }} alt="Logo" />
            <Flex f={1} column>
              <div style={{ fontSize: 8 }}>{username || ''}</div>
              {/* <div style={{ fontSize: 6 }}>words</div> */}
            </Flex>
            <MoreVertIcon style={{ color: colors.offwhite, marginRight: 5 }} />
          </Fab>
          <Popover
            open={menuOpen}
            anchorEl={fabRef.current}
            onClose={handleMenuClose}
            style={{ marginTop: 20 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem style={{ backgroundColor: colors.lightest, color: colors.white }} onClick={() => {
              handleMenuClose();
              handleLogout();
            }}>Logout</MenuItem>
          </Popover>
        </Flex>
      </Flex>
      <Notification dimensions={dimensions} notification={notification}/>
    </>
  );
}

const styles = {
  container: {
    zIndex: 99,
    position: 'relative',
    backgroundColor: colors.mid,
    padding: 10
  },
  profileButton: {
    zIndex: 99,
    backgroundColor: colors.light, 
    color: colors.white, 
    width: 180, 
    height: 40, 
    borderRadius: 20,
    justifyContent: 'flex-start'
  }
}