import { useEffect, useState } from "react";
import api from "../../../../../api.js";
import colors from "../../../../../colors.js";
import Flex from "../../../../global/Flex.js";
import TextField from '../../../../global/TextField.js';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material/';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';


export default function({ provider_data }) {

  async function handleDownloadInternalReport() {
    const res = await api.getInternalDataReport({ 
      provider_id: provider_data.provider_id, 
      provider_name: provider_data.provider_name, 
      provider_data_id: provider_data.id, 
      filename: provider_data.filename, 
      type: provider_data.type
    });
    let link = document.createElement('a');
    link.href = res;
    link.download = provider_data.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Flex column f={1} alss>
      <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>View Provider Data</Flex>
      <Flex style={{ marginBottom: 20 }} />
      <Flex column alss style={{ margin: 5 }}>

        {/* BASE INFO */}
        <Flex alss>
          <TextField disabled label='Provider' value={provider_data.provider_name} />
          <TextField disabled label='ID' value={provider_data.id} />
        </Flex>
        <TextField style={{ marginRight: 20 }} disabled label='Filename' value={provider_data.filename && provider_data.filename.split('/')[provider_data.filename.split('/').length - 1]} />
        <TextField style={{ marginRight: 20 }} disabled label='S3 Storage Path' value={provider_data.storage_path_raw || 'Not Yet Stored'} />
        
        <Flex alss>
          <TextField disabled label='Capture Date' value={provider_data.created_date.split('T')[0]} />
          <TextField disabled label='Updated Date' value={provider_data.updated_date.split('T')[0]} />
        </Flex>
        <Flex alss>
          <TextField disabled label='State' value={provider_data.dead_letter ? 'errored' : provider_data.state} />
          <TextField disabled style={{ opacity: 0 }} />
        </Flex>

      {/* Supplemental Files */}
      <Accordion sx={{ width: '100%', backgroundColor: colors.darkest }} disableGutters={true} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: colors.lightest }}/>}>
          <Typography style={{ color: colors.lightest, fontStyle: 'italic' }} typography={'accordion'}>Supplemental Files ({provider_data.supplemental?.length || 0})</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ transform: 'translateY(-10px)', backgroundColor: colors.darkest}}>
          {!provider_data.supplemental || provider_data.supplemental?.length === 0 ? <Flex style={{ color: colors.grey }}>No Supplemental Files</Flex> : null}
          {provider_data.supplemental?.map(({ filename, state, updated_date }) => (
            <Flex alss column style={{ marginBottom: 10 }}>
              <TextField style={{ marginRight: 20 }} disabled label='Filename' value={filename} />
              <Flex>
                <TextField disabled label='State' value={state} />
                <TextField disabled label='Updated Date' value={updated_date ? updated_date.split('T')[0] : 'None'} />
              </Flex>
            </Flex>
          ))}
        </AccordionDetails>
      </Accordion>


      {/* DEAD LETTER */}
      <Accordion sx={{ width: '100%', backgroundColor: colors.darkest }} disableGutters={true} elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: colors.lightest }}/>}>
          <Typography style={{ color: colors.lightest, fontStyle: 'italic' }} typography={'accordion'}>Dead Letter ({provider_data.dead_letter ? 1 : 0})</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ transform: 'translateY(-10px)', backgroundColor: colors.darkest}}>
          {!provider_data.dead_letter && <Flex style={{ color: colors.grey }}>No Dead Letter</Flex>}
          {provider_data.dead_letter && <Flex f={1} alss column style={{ margin: 5 }}>
            <Flex style={{ color: colors.grey }}>Message</Flex>
            <Flex style={{ 
              backgroundColor: colors.lightest, 
              color: 'black', 
              borderRadius: 5, 
              border: `1px solid ${colors.offwhite}`,
              flexWrap: 'nowrap',
              padding: 5,
              margin: 5
            }}>{provider_data.dead_letter.message}</Flex>
            <Flex style={{ color: colors.grey }}>Error Stack</Flex>
            <Flex style={{ 
              backgroundColor: colors.lightest, 
              color: 'black', 
              borderRadius: 5, 
              border: `1px solid ${colors.offwhite}`,
              flexWrap: 'nowrap',
              padding: 5,
              margin: 5
            }}>{JSON.stringify(provider_data.dead_letter.error, null, 2)}</Flex>
          </Flex>
          }
        </AccordionDetails>
      </Accordion>
      <br/>
      <br/>
      <br/>
      <div style={{ cursor: 'pointer', color: colors.secondary, marginLeft: 20 }} onClick={handleDownloadInternalReport}>DOWNLOAD REPORT</div>
      </Flex>
    </Flex>
  )
}
