import { useEffect, useState } from "react";
import moment from 'moment';
import Flex from "../../../global/Flex";
import api from "../../../../api";
import Table from "../../../global/Table";


export default function() {
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    (async function() {
      const sessionResponse = await api.getSessions();
      setSessions(sessionResponse);
    }());
  }, []);

  return (
    <Flex column f={1} alss>
      <Table
        title={`Sessions`}
        data={sessions}
        structure={[
          { column: 'Name', key: 'user_name', style: { minWidth: 200 } },
          { column: 'Organization', key: 'organization_identifier', style: { minWidth: 140 } },
          { column: 'Email', key: 'user_email', style: { minWidth: 260 } },
          { column: 'Last activity (UTC)', key: 'last_refresh_date', mutation: str => (str || '').split('T')[0], style: { minWidth: 140 } },
          { column: 'Last activity', key: 'last_refresh_date', mutation: str => moment(str).fromNow(), style: { minWidth: 100 } },
        ]}
      />
    </Flex>
  )
}

