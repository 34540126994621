// icons
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SourceIcon from '@mui/icons-material/Source';
import FilterAlt from '@mui/icons-material/FilterAlt';

// Sections
import Authentication from './authentication/index.js';
import Data from './data/index.js';


export default [
  {
    section: 'Authentication', 
    children: [
      { 
        id: 'organizations',
        icon: color => <MapsHomeWorkIcon style={ { color, fontSize: '20px' }} />, 
        text: 'Organizations', 
        Component: Authentication.Organizations.Page
      },
      { 
        id: 'sessions',
        icon: color => <AccessTimeIcon style={ { color, fontSize: '20px' }} />, 
        text: 'Sessions', 
        Component: Authentication.Sessions.Page
      }
    ]
  },
  { 
    section: 'Pipeline', 
    children: [
      { 
        id: 'pipeline_events',
        icon: color => <FilterAlt style={ { color, fontSize: '20px' }} />, 
        text: 'Events', 
        Component: Data.PipelineEvents.Page
      },
      { 
        id: 'provider_data',
        icon: color => <SourceIcon style={ { color, fontSize: '20px' }} />, 
        text: 'Provider Data', 
        Component: Data.ProviderData.Page
      },
    ]
  },
]