import { useEffect, useState } from "react";
import colors from "../../../../../colors.js";
import Flex from "../../../../global/Flex.js";
import { Modal, Card, CardContent, Typography, Button, Box } from '@mui/material';

export default function({ isOpen, handleOpenAddMappingModalClosed, parsedError}) {
  return (
    <Modal
        open={isOpen}
        onClose={handleOpenAddMappingModalClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={style}>
            <div>{parsedError?.provider_name}</div>
            <div>{parsedError?.key_name}</div>
            <div>{parsedError?.provider_value}</div>
        </Box>
      </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: colors.mid,
  boxShadow: 24,
  p: 4,
};