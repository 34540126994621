import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header.js'
import api from '../api.js';
import Flex from './global/Flex.js';
import Navigation from './pages/Navigation.js';
import pages from './pages/index.js';

const dimensions = {
  height_header: 40,
  width_navigation: 180
}

export default ({ session, logout }) => {
  const [page, setPage] = useState({});
  const [notification, setNotification] = useState(null);

  function handleSetPage({ id, component_primary }) {
    setPage(prev => ({ ...prev, id, component_primary }));
  }

  async function handleLogout() {
    await api.logout();
    logout();
  }

  function createNotification({ type, text }) {
    setNotification({ type, text });
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  }

  return (
    <Router>
      <Flex column style={{ width: '100vw', overflow: 'hidden', position: 'relative' }} f={1} alss>
        <Header username={session?.user_identifier} dimensions={dimensions} notification={notification} handleLogout={handleLogout} />
        <Flex f={1} alss style={{ height: '100vh' }}>
          <Navigation dimensions={dimensions} page_id={page.id} handleSetPage={handleSetPage} />
          <Flex row alss f={1} style={{ maxWidth: 1200 }}>
            <Routes>
              {pages.map(({ children }) => 
                children.map(({ id, Component }, i) => 
                  <Route key={i} path={`/${id}`} element={<Component createNotification={createNotification} />} />
                )
              )}
            </Routes>
          </Flex>
        </Flex>
      </Flex>
    </Router>
  );
}
