import { useEffect, useState } from 'react';
import logoColor from '../assets/logo-color.png';
import { DotLoader } from 'react-spinners';
import api from '../api.js';
import colors from '../colors';

export default function ({ login, session, isLoggedIn, isLoginError }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, toggleShowForgotPassword] = useState(false);

  function handleSetEmail(em) {
    setEmail(em);
  }
  function handleSetPassword(pw) {
    setPassword(pw);
  }

  async function handleLogin() {
    await login({ email, password });
  }

  function handleToggleShowForgotPassword(bool = false) {
    toggleShowForgotPassword(bool);
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={{ padding: '20px' }}>
          <img src={logoColor} style={{ height: 'auto', width: '90%' }} alt="Logo" />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <div style={{ color: colors.white, fontWeight: 500, fontStyle: 'italic', fontSize: '20px' }}>Admin Portal</div>
            <div style={{ display: !showForgotPassword ? 'none' : '', color: 'white', fontWeight: 500, fontStyle: 'italic', fontSize: '14px' }}>Password Reset</div>
          </div>
        </div>
        {
        showForgotPassword ? 
          <ForgotPassword handleToggleShowForgotPassword={handleToggleShowForgotPassword} />
        : 
        !isLoggedIn ?
          <InputArea 
            email={email} 
            password={password}
            isLoginError={isLoginError}
            handleLogin={handleLogin}
            handleSetEmail={handleSetEmail}
            handleSetPassword={handleSetPassword}
            handleToggleShowForgotPassword={handleToggleShowForgotPassword}
          />
        :
        <DataLoading user_name={session.user_name}/>}
      </div> {/* End Card */}
    </div>
  );
}


function InputArea({ isLoginError, email, password, handleLogin, handleSetEmail, handleSetPassword, handleToggleShowForgotPassword }) {
  useEffect(() => {
    handleSetEmail('');
    handleSetPassword('');
  }, [isLoginError]);
  return (
    <div style={{ height: '80%', backgroundColor: colors.mid, display: 'flex', flexDirection: 'column', padding: '20px', fontWeight: 700, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
      Sign in with your email and password
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <label style={styles.inputLable} htmlFor="email">Email</label>
          <div style={{ marginRight: '20px', color: colors.red, opacity: isLoginError ? 1 : 0 }}>Invalid Login</div>
        </div>
        <input 
          onChange={({ target }) => handleSetEmail(target.value)}
          value={email}
          style={styles.input} 
          type="text" placeholder="name@org.com" name="email" id="email"
        ></input>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
          <label style={styles.inputLable} htmlFor="password">Password</label>
          {/* <span onClick={() => handleToggleShowForgotPassword(true)} style={{ fontSize: '14px', marginTop: '16px', color: colors.secondary, marginRight: '14px', padding: '4px', cursor: 'pointer' }}>Forgot Password</span> */}
        </div>
        <input 
          onChange={({ target }) => handleSetPassword(target.value)} 
          value={password}
          style={styles.input} 
          type="password" placeholder="password" name="password" id="password"
        ></input>
      </div>
      <div onClick={handleLogin} style={{ ...styles.button, marginTop: '35px' }}><span>Sign in</span></div>
    </div> 
  )
}

function DataLoading({ user_name }) {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontWeight: 700, color: 'white', paddingBottom: '50px', zIndex: 1001 }}>
      <div style={{ marginBottom: '10px', fontSize: '32px'}}>Welcome {user_name}!</div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ fontSize: '24px', color: '#fcc414', marginBottom: '30px' }}>Generating your report</div>
        <DotLoader color="#fcc414" />
      </div>
    </div>
  )
}

function ForgotPassword({ handleToggleShowForgotPassword }) {
  const [email, setEmail] = useState('');
  const [tokenHasBeenSent, setTokenHasBeenSent] = useState(false);
  const [tokenSent, setTokenSent] = useState(false);

  async function sendToken() {
    try {
      if (email.length < 1) return;
      await api.sendResetToken({ email });
      setTokenSent(true);
      setTokenHasBeenSent(true);
    } catch(err) {
      setTokenSent(true);
      setTokenHasBeenSent(true);
      console.log(err);
    }
  }

  function handleSetEmail(em) {
    setEmail(em);
  }

  useEffect(() => {
    setEmail('');
  }, []);

  useEffect(() => {
    setTimeout(() => setTokenSent(false), 5000);
  }, [tokenSent]);
  return (
    <div style={{ height: '80%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: '20px', fontWeight: 700, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
      Please enter your email address
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <label style={styles.inputLable} htmlFor="email">Email</label>
            <div style={{ marginRight: '20px', color: 'green', opacity: tokenSent ? 1 : 0 }}>Email Sent!</div>
          </div>
          <input 
            onChange={({ target }) => handleSetEmail(target.value)}
            value={email}
            style={styles.input} 
            type="text" placeholder="name@org.com" name="email" id="email"
          ></input>
        </div>
        <div style={{ display: tokenHasBeenSent ? '' : 'none' }}>
          <div style={{ marginTop: '14px', fontSize: '15px', fontWeight: 'normal' }}>
            Assuming your email was correct, you should receive an email with a reset link shortly! 
          </div>
          <div style={{ marginTop: '14px', fontSize: '15px', fontWeight: 'normal' }}>
            <b></b>Note: Please check spam if you do not see an email!
          </div>
        </div>
      </div>
      <button onClick={sendToken} style={{ 
        ...styles.button, 
        cursor: email.length < 1 ? '' : 'pointer',
        backgroundColor: email.length < 1 ? 'grey' : '#0367B4'
      }}>
        <span>{!tokenHasBeenSent ? 'Send Email' : 'Resend Email'}</span>
      </button>
      <span onClick={() => handleToggleShowForgotPassword(false)} style={{ alignSelf: 'center', padding: '10px', color: '#0367B4', cursor: 'pointer' }}>Cancel</span>
    </div> 
  )
}

const styles = {
  container: {
    zIndex: 101,
    position: 'absolute',
    top: 0, left: 0, right: 0, bottom: 0,
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.darkest,
  },
  topBlueMargin: { 
    display: 'flex', 
    flexDirection: 'column', 
    height: '100%', 
    borderRadius: '8px', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  card: { 
    display: 'flex',
    flexDirection: 'column', 
    // backgroundColor: '#0367B4',
    background: `linear-gradient(90deg, ${colors.light} 3%, ${colors.mid} 80%, ${colors.dark} 100%)`, 
    width: '400px', 
    height: '500px',
    borderRadius: '8px'
  },
  input: {
    width: '95%',
    height:'5vh',
    fontSize: '16px',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    color: colors.darkest,
    textIndent: '1.5vh'
  },
  inputLable: {
    display: 'block',
    color: colors.offwhite,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    paddingTop: '.5rem',
    paddingBottom: '.5rem'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.light,
    color: colors.white,
    fontSize: '20px',
    fontWeight: 700,
    padding: '10px 60px',
    borderRadius: '5px',
    margin: '10px 0px',
    cursor: 'pointer'
  }
}