import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Tooltip } from '@mui/material';
import Flex from "../../../global/Flex";
import api from "../../../../api";
import { visuallyHidden } from '@mui/utils';
import DrawerHandleProviderData from './drawers/HandleProviderData.js';
import colors from '../../../../colors.js';
import Drawer from "../../Drawer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import { PacmanLoader } from 'react-spinners';
import moment from "moment";
import AddProviderKeyMapping from "./popups/AddProviderKeyMapping.js";


export default function({ handleSetDrawerComponent }) {
  const [providerData, setProviderData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [drawerOpen, setDrawer] = useState('closed');
  const [addMappingModalOpen, setAddMappingModalOpen] = useState(false);
  const [parsedError, setParsedError] = useState(null);

  useEffect(() => {
    (async function() {
      const res = await api.getProviderData();
      setProviderData(res);
    }());
  }, []);

  async function handleSelection(provider_data) {
    setSelected(provider_data);
    if (provider_data?.dead_letter?.error?.stack) handleErrorParsing(provider_data.dead_letter.error.stack);
    setDrawer('open');
  }

  async function handleOpenAddMappingModalOpen() {
    setAddMappingModalOpen(true);
  }

  async function handleOpenAddMappingModalClosed() {
    setAddMappingModalOpen(false);
  }

  function handleErrorParsing(stack) {
    const [provider_name, key_name, provider_value] = stack.match(/\[(.*?)\]/)[0].replace('[','').replace(']','').split('|');
    setParsedError({ provider_name, key_name, provider_value });
  }

  return (
    <Flex f={1} column style={{ alignSelf: 'stretch' }}>
      <Drawer width={600} open={drawerOpen === 'open'} close={() => {
        setDrawer('closed');
        setSelected(null);
      }}>
        {drawerOpen === 'open' ? <DrawerHandleProviderData 
                                    provider_data={selected} 
                                    handleOpenAddMappingModalOpen={handleOpenAddMappingModalOpen}
                                  /> 
        : null}
      </Drawer>
      <EnhancedTable rows={providerData} selected={selected} handleSelection={handleSelection} />
      <AddProviderKeyMapping isOpen={addMappingModalOpen} parsedError={parsedError} handleOpenAddMappingModalClosed={handleOpenAddMappingModalClosed} />
    </Flex>
  );
}

function EnhancedTable({ rows = [], selected, handleSelection }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState([]);
  const [emptyRows, setEmptyRows] = useState([]);

  useEffect(() => {
    // Avoid a layout jump when reaching the last page with empty rows.
    const empty = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(empty);
    const comparator = getComparator(order, orderBy);
    const visRows = stableSort(rows, comparator).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setVisibleRows(visRows);
  }, [rows, order, orderBy, page, rowsPerPage]);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (!a || !b) {
      console.error('Invalid arguments', { a, b, orderBy });
      return 0;
    }
  
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function resolveStateIcon(state) {
    switch(state) {
      case 'completed': return <CheckCircleIcon style={{ color: colors.green }}/>;
      case 'errored': return <FmdBadIcon style={{ color: colors.secondary }}/>;
      case 'captured': 
      case 'accepted': 
      case 'converted': 
      case 'ingested': return <Flex style={{ transform: 'translate(2px, -5px)', paddingTop: 10, paddingBottom: 10 }}><PacmanLoader speedMultiplier={1} color={colors.blue} size={10} /></Flex>
    }
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
      <Flex alss style={{ paddingLeft: 40 }}>
        <Box sx={{ width: '100%' }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((provider_data, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={index} sx={{ cursor: 'pointer', '&:hover': colors.lightest, borderLeft: `3px solid ${selected?.id === provider_data.id ? colors.secondary : colors.light}`, borderTop: `1px solid ${colors.light}` }} onClick={() => handleSelection(provider_data)}>
                      <TableCell style={{ fontSize: 14, borderBottom: `1px solid ${colors.light}`, color: colors.white, padding: 5, paddingLeft: 15 }} component="th" id={labelId} scope="row" padding="none">{resolveStateIcon(provider_data.dead_letter ? 'errored' : provider_data.state)}</TableCell>
                      <TableCell style={{ fontSize: 14, borderBottom: `1px solid ${colors.light}`, color: colors.white, padding: 5, paddingLeft: 15 }} component="th" id={labelId} scope="row" padding="none">{moment(provider_data.created_date).fromNow()}</TableCell>
                      <TableCell style={{ fontSize: 14, borderBottom: `1px solid ${colors.light}`, color: colors.white, padding: 5, paddingLeft: 15 }} component="th" id={labelId} scope="row" padding="none">{provider_data.provider_name}</TableCell>
                      <TableCell style={{ fontSize: 14, borderBottom: `1px solid ${colors.light}`, color: colors.white, padding: 5, paddingLeft: 15 }} component="th" id={labelId} scope="row" padding="none">{provider_data.source_identifier}</TableCell>
                      <TableCell style={{ fontSize: 14, borderBottom: `1px solid ${colors.light}`, color: colors.white, padding: 5, paddingLeft: 15 }} component="th" id={labelId} scope="row" padding="none">{provider_data.filename && provider_data.filename.split('/')[provider_data.filename.split('/').length - 1]}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ color: colors.white }}
            />
        </Box>
      </Flex>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };
  return (
    <TableHead>
      <TableRow>
        {[
          {
            id: 'state',
            numeric: false,
            disablePadding: true,
            label: 'State'
          },
          {
            id: 'created_date',
            numeric: false,
            disablePadding: true,
            label: 'Capture Date'
          },
          {
            id: 'provider_name',
            numeric: false,
            disablePadding: true,
            label: 'Provider'
          },
          {
            id: 'source_identifier',
            numeric: false,
            disablePadding: true,
            label: 'Integration'
          },
          {
            id: 'filename',
            numeric: false,
            disablePadding: true,
            label: 'Filename'
          }
        ].map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ borderBottom: `1px solid ${colors.light}` }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ position: 'relative', color: colors.grey }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
    <Typography
      sx={{ flex: '1 1 100%' }}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      {props.procedure_name}
    </Typography>
    </Toolbar>
  );
}
