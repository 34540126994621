import { FormControl, MenuItem, Select, OutlinedInput, InputLabel } from "@mui/material";
import colors from '../../colors.js';

export default function({ label, value, options = [], onChange, disabled, width }) {
  return (
    <FormControl sx={{ m: 0, minWidth: 120 }}>
      <InputLabel sx={{ color: 'white', fontSize: '14px'}}>{label}</InputLabel>
      <Select
        disabled={!!disabled}
        value={value || ''}
        onChange={onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          width: width || '100%',
          fontSize: 12,
          height: 30,
          margin: .6,
          color: disabled ? 'white' : 'white',
          '& .MuiSelect-icon': { color: 'white' }, // This targets the dropdown icon
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              
              borderColor: "white"
            },
            "&:hover fieldset": {
              borderColor: "white"
            },
            "&.Mui-focused fieldset": {
              borderColor: "white"
            },
            "&.Mui-disabled fieldset": {
              borderColor: "white" // This targets the border color when the component is disabled
            },
          }
        }}
      >
        {options.map(({ value, label }, i) => <MenuItem key={i} value={value}>{label}</MenuItem>)}
      </Select>
    </FormControl>
  )
}
