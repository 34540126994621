import Organizations from './organizations/Content.js';
import Sessions from './sessions/Content.js';
import CreateUser from './organizations/drawers/CreateUser.js';
import EditUser from './organizations/drawers/EditUser.js';

export default {
  Organizations: {
    Page: Organizations,
    Drawers: {
      CreateUser: CreateUser,
      EditUser: EditUser
    }
  },
  Sessions: {
    Page: Sessions
  }
}