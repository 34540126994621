import { Button } from "@mui/material";
import colors from "../../colors.js";
import Flex from '../global/Flex.js'
import { useEffect } from "react";
import { PulseLoader } from 'react-spinners';

export default function({ open, close, saveStatus = 'disabled', onSave, children, width }) {
  useEffect(() => {
    if (!['disabled', 'loading', 'active'].includes(saveStatus)) throw Error(`Improper use of Drawer save status: ${saveStatus}`);
  }, [saveStatus]);
  return (
    <Flex column f={1} alss style={{
      ...styles.container,
      transform: open ? 'translateX(0%)' : 'translateX(100%)',
      transition: 'transform 0.2s ease-in-out',
      width: width || undefined
    }}>
      <Flex alss jcsb>
        <Button onClick={close} style={{ fontSize: 12, padding: 10, color: colors.red, fontWeight: 'bold', transform: 'translateX(-10px)' }}>Close</Button>
        {saveStatus === 'loading' ? 
        <PulseLoader color={colors.secondary} size={8} style={{ marginTop: 16, marginRight: 5 }} />
        :
        <Button 
          disabled={saveStatus === 'disabled'} 
          onClick={onSave}
          style={{ fontSize: 12, padding: 10, color: saveStatus === 'active' ? colors.secondary : colors.grey, fontWeight: 'bold' }}
        >Save</Button>}
      </Flex>
      {children}
    </Flex>
  );
}

const styles = {
  container: { 
    zIndex: 100,
    position: 'absolute',
    top:0, bottom:0, right:0,
    width: 400,
    backgroundColor: colors.darkest,
    paddingLeft: 12,
    paddingRight: 12
  }
}