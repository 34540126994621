import { TextField } from "@mui/material";
import colors from '../../colors.js';


export default function({ label, value, onChange, disabled, style }) {
  return (
  <TextField
    disabled={!!disabled}
    focused
    label={label}
    variant="outlined"
    value={value}
    onChange={onChange}
    fullWidth
    sx={{
      margin: .6,
      ".MuiOutlinedInput-root": {
        '&.Mui-focused fieldset': { borderColor: colors.light }, // border no hover
        '&.Mui-disabled fieldset': { borderColor: colors.light }, // border no hover (disabled)
        '&:hover fieldset': { borderColor: colors.light }, // border hover (both)
      },
      ".MuiOutlinedInput-input": {
        padding: '10px',
        fontSize: '12px',
        color: colors.white,  // value text
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: colors.white, // value text (disabled)
      },
      ".MuiFormLabel-root": {
        // color: colors.red,
        color: colors.white,  // label text
        '&.Mui-disabled': { color: colors.grey },   // label text (disabled)
      },
      ...style
    }}
  />
  )
}
