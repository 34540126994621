import { useEffect, useState, useRef } from "react";
import Flex from "../../../global/Flex";
import api from "../../../../api";
import Table from "../../../global/Table";
import DrawerCreateUser from './drawers/CreateUser.js';
import DrawerEditUser from './drawers/EditUser.js';
import colors from "../../../../colors";
import Drawer from "../../Drawer";


const newUserDefault = { name: '', email: '', surgeon_id: '', role_access: 'personal', role_scope: 'personal' };

export default function({ createNotification }) {
  const [drawer, setDrawer] = useState(null);
  const [saveStatus, setSaveStatus] = useState('disabled');
  const onSaveRef = useRef(null);
  const [selected, setSelected] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [linkableSurgeons, setLinkableSurgeons] = useState([]);
  // create user
  const [newUser, setNewUser] = useState(newUserDefault);
  // edit user
  const [stagedChanges, setStagedChanges] = useState({});

  useEffect(() => {
    (async function() {
      const res = await api.getOrganizationDetails();
      setOrganizations(res);
      setSelectedOrganization(res[0]);
    }());
  }, []);

  useEffect(() => {
    if (!selectedOrganization || selectedOrganization.organization_identifier === 'admin') return;
    (async function () {
      const linkableSurgeonsRes = await api.getLinkableSurgeons({ organization_id: selectedOrganization.organization_id });
      setLinkableSurgeons(linkableSurgeonsRes);
    }())
  }, [selectedOrganization]);

  function closeDrawer() {
    setSelected(null);
    setNewUser(newUserDefault);
    setStagedChanges({});
    setDrawer(null);
  }

  async function createUser() {
    setSaveStatus('loading');
    try {
      await api.createUser({ organization_id: selectedOrganization.organization_id, ...newUser });
      createNotification({ type: 'success', text: `Success! '${selectedOrganization.organization_identifier}' user created: '${newUser.name}' - '${newUser.email}' (Refresh may be required)` });
      closeDrawer();
    } catch(err) {
      if (err?.response?.data === 'Email already exists') return createNotification({ type: 'failure', text: `Error! ${err.response.data}` });
      console.log(err);
      return createNotification({ type: 'failure', text: `Error! Unable to create user` });
    } finally {
      setSaveStatus('disabled');
    }
  }

  async function editUser() {
    setSaveStatus('loading');
    try {
      await api.updateUser({ user_id: selected.id,  ...selected, ...stagedChanges });
      createNotification({ type: 'success', text: `Success! Updated user: '${newUser.name}' (Refresh may be required)` });
      closeDrawer();
    } catch(err) {
      console.log(err);
      return createNotification({ type: 'failure', text: `Error! Unable to edit user` });
    } finally {
      setSaveStatus('disabled');
    }
  }

  async function onSave() {
    switch(drawer) {
      case 'CREATE_USER': return createUser();
      case 'EDIT_USER': return editUser();
      default: return () => {};
    }
  }

  async function downloadApiRequests() {
    const { signed_url } = await api.getAPIRequests({ organization_id: selectedOrganization.organization_id });
    const link = document.createElement('a');
    link.href = signed_url;  // Directly assign the signed URL to the href
    link.setAttribute('download', `${selectedOrganization.organization_identifier}-${new Date().toISOString().split('T')[0]}.csv`); // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Flex column f={1} alss>
      <Drawer 
        open={!!drawer} 
        close={closeDrawer}
        saveStatus={saveStatus}
        // onSave={onSaveRef.current}
        onSave={onSave}
      >
      {
        drawer === 'CREATE_USER' ?
          <DrawerCreateUser
            organization={selectedOrganization}
            newUser={{ value: newUser, set: newObj => setNewUser(prev => ({ ...prev, ...newObj }))}}
            linkableSurgeons={linkableSurgeons}
            setSaveStatus={(val) => setSaveStatus(val)}
          />
        : drawer === 'EDIT_USER' ?
          <DrawerEditUser 
            user={selected}
            organization={selectedOrganization}
            stagedChanges={{ value: stagedChanges, set: newObj => setStagedChanges(prev => ({ ...prev, ...newObj }))}}
            linkableSurgeons={linkableSurgeons}
            setSaveStatus={(val) => setSaveStatus(val)}
          />
        : null
      }
      </Drawer>
      <Table
        title={`Organizations`}
        data={organizations}
        handleSelection={org => setSelectedOrganization(org)}
        selected={{ key: 'organization_id', value: selectedOrganization?.organization_id }}
        structure={[
          { column: 'Identifier', key: 'organization_identifier', style: { minWidth: 150 } },
          { column: 'Type', key: 'is_admin',  style: { minWidth: 150 } },
        ]}
        actionComponent={
          <Flex 
            onClick={() => downloadApiRequests({ organization_id: selectedOrganization?.organization_id })} 
            style={styles.addButton}>
              Download API History
          </Flex>
        }
      />
      <Table 
        title={`Data Access (${selectedOrganization?.organization_identifier})`}
        data={selectedOrganization?.organization_providers}
        structure={[
          { column: 'Provider', key: 'provider', style: { minWidth: 150 } },
          { column: 'Permission', key: 'permission', style: { minWidth: 150 } }
        ]}
      />
      <Table 
        title={`Users (${selectedOrganization?.organization_identifier})`}
        data={selectedOrganization?.users}
        selected={{ key: 'email', value: selected?.email }}
        handleSelection={async (user) => {
          if (user.surgeon_id) {
            const surgeon = await api.getSurgeonData({ surgeon_id: user.surgeon_id });
            setSelected({ ...user, surgeon_linked: surgeon });
          } else setSelected(user);
          setDrawer('EDIT_USER');
        }}
        structure={[
          { column: 'Name', key: 'name', style: { minWidth: 150 } },
          { column: 'Role Access', key: 'role_access', style: { minWidth: 150 }, hide: selectedOrganization?.organization_identifier === 'admin' },
          { column: 'Role Scope', key: 'role_scope', style: { minWidth: 150 }, hide: selectedOrganization?.organization_identifier === 'admin' },
          { column: 'Linked', key: 'surgeon_id', mutation: id => id ? 'Yes' : '', style: { minWidth: 150 }, hide: selectedOrganization?.organization_identifier === 'admin' },
          { column: 'Email', key: 'email', style: { minWidth: 200 } },
          { column: 'Last Login', key: 'last_session_date', mutation: str => (str || '').split('T')[0], style: { minWidth: 200 }}
        ]}
        actionComponent={<Flex onClick={() => setDrawer('CREATE_USER')} style={styles.addButton}> Add User</Flex>}
      />
    </Flex>
  )
}

const styles = {
  addButton: {
    fontWeight: 'bold',
    color: colors.secondary,
    cursor: 'pointer',
    padding: 10
  }
}
