import { useEffect, useState } from "react";
import colors from "../../../../../colors.js";
import Flex from "../../../../global/Flex";
import TextField from '../../../../global/TextField.js';
import Select from '../../../../global/Select.js';

const errorMessages = {
  linkRequired: '* Surgeon link required to resolve location',
  noEnterpriseLink: '* Surgeon link is incompatible with non enterprise scope',
  onlyPersonalPersonal: '* Personal access is only compatible with personal scope'
}

export default function({ organization, user, stagedChanges, setSaveStatus, linkableSurgeons }) {
  const [error, setError] = useState({ linkRequired: '' });

  useEffect(() => {
    setSaveStatus('disabled');
    const role_access = stagedChanges.value.role_access || user.role_access;
    const role_scope = stagedChanges.value.role_scope || user.role_scope;
    const surgeon_id = stagedChanges.value.surgeon_id || user.surgeon_id;
    if ((role_access === 'personal' || role_scope !== 'enterprise') && !surgeon_id) return setError(prev => ({ ...prev, linkRequired: errorMessages.linkRequired }));
    else if (role_access === 'personal' && role_scope !== 'personal') return setError(prev => ({ ...prev, linkRequired: errorMessages.onlyPersonalPersonal }));
    else setError({ linkRequired: '' });
    if (user.role_access !== role_access ||user.role_scope !== role_scope ||user.surgeon_id !== surgeon_id) setSaveStatus('active');
  }, [stagedChanges.value]);

  if (organization?.organization_identifier === 'admin') return <Flex column f={1} alss>Admin account edits are currently disabled</Flex>
  return (
    <Flex column f={1} alss>
      <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Edit User</Flex>
      <Flex style={{ marginBottom: 20 }} />
      <Flex column alss style={{ margin: 5 }}>

        {/* USER INFO */}
        <Flex alss>
          <TextField disabled label='Organization' value={organization.organization_identifier} />
        </Flex>
        <Flex alss>
          <TextField disabled label='Name' value={user.name} />
          <TextField disabled label='Email' value={user.email} />
        </Flex>

        <Flex style={{ marginBottom: 20 }} />

        {/* LINK SURGEON */}
        <Flex column alss>
          <Flex jcsb alss aibl>
            <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Link Surgeon Data</Flex>
            <div style={{ color: colors.red, fontStyle: 'italic', fontSize: 10}}>{error.linkRequired}</div>
          </Flex>
          <Flex style={{ marginBottom: 20 }} />
          <Flex alss aic>
              <Select
              width={240}
              value={stagedChanges.value.surgeon_id ? stagedChanges.value.surgeon_id.toString() : user.surgeon_linked.id.toString()}
              onChange={({ target }) => {
                stagedChanges.set({ surgeon_id: target.value })
              }}
              label='Linkable Surgeons'
              options={linkableSurgeons.map(({ id, name, npi }) => ({ value: id, label: `${name} - ${npi}`}))}
              />
            <Flex style={{ marginLeft: 10 }} />
            {/* <div style={{ color: colors.secondary, fontWeight: 'bold', padding: 10, cursor: 'pointer' }}>UNLINK</div> */}
          </Flex>
        </Flex>


          <Flex style={{ marginBottom: 20 }} />


          {/* ROLES PERMISSIONS */}
          <Flex column style={{ }}>
            <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Roles/Permissions</Flex>
            <Flex style={{ marginBottom: 20 }} />
            <Flex column style={{ fontSize: 10, color: colors.offwhite, fontStyle: 'italic' }}>
              <div style={{ marginBottom: 3 }}>With the below permissions, user can:</div>
              <div style={{ marginLeft: 10, marginBottom: 3 }}>- View data outside their personal zone</div>
              <div style={{ marginLeft: 10, marginBottom: 3 }}>- Can access high permission features (ex: Surgeon v Surgeon comparison)</div>
            </Flex>
            <Flex style={{ marginBottom: 20 }} />
            <Flex alss>
              <Select 
                value={stagedChanges.value.role_access || user.role_access || ''}
                onChange={({ target }) => stagedChanges.set({ role_access: target.value })}
                label='Access'
                options={[
                  { value: 'personal', label: 'Personal' },
                  { value: 'global', label: 'Global' },
                ]}
              />
              <Flex style={{ marginRight: 20 }} />
              <Select 
                value={stagedChanges.value.role_scope || user.role_scope || ''}
                onChange={({ target }) => stagedChanges.set({ role_scope: target.value })}
                label='Scope'
                options={[
                  { value: 'personal', label: 'Personal' },
                  { value: 'hospital', label: 'Hospital' },
                  { value: 'state', label: 'State' },
                  { value: 'enterprise', label: 'Enterprise' }
                ]}
              />
            </Flex>
          </Flex>
      </Flex>
    </Flex>
  )
}
