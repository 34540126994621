import Overview from './overview/Content.js';
import ProviderData from './provider_data/Content.js';
import PipelineEvents from './pipeline_events/Content.js';

export default {
  Overview: {
    Page: Overview,
  },
  ProviderData: {
    Page: ProviderData
  },
  PipelineEvents: {
    Page: PipelineEvents
  },

}