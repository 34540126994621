import { List, ListItem, ListItemButton } from '@mui/material';
import colors from "../../colors";
import Flex from "../global/Flex";

export default function({ title, actionComponent, structure, data = [], selected, handleSelection }) {
  return (
    <List style={{ maxHeight: '60%', alignSelf: 'stretch', padding: 15 }}>
      <Flex jcsb>
        <div style={{ fontSize: 20, fontStyle: 'italic', color: colors.offwhite }}>{title}</div>
        {actionComponent || null}
      </Flex>
      <Flex column f={1} alss style={styles.content}>
        <RowHeader structure={structure} />
        {data?.length === 0 ? <Flex style={{ ...styles.emptyState, padding: 10}}>No data found</Flex> : null}
        {data?.map((d, i) => (
          <Row
            key={i}
            data={d}
            structure={structure}
            is_selected={selected && d[selected?.key] === selected?.value}
            handleSelection={handleSelection}
          />
        ))}
      </Flex>
    </List>
  )
}

function RowHeader({ structure = [] }) {
  return (
    <Flex alss style={{ borderBottom: `1px solid ${colors.mid}`, paddingBottom: 4, marginLeft: 10 /* matching left border width */ }}>
      {structure.map(({ column, style, hide }, i) => {
        if (hide) return null;
        return <Flex key={i} style={{ color: colors.offwhite, fontStyle: 'italic', flexWrap: 'wrap', ...style }}>{column}</Flex>
      })}
    </Flex>
  );
}

function Row({ data, structure, is_selected, handleSelection }) {
  function resolveRowValue(rowData, key, mutation) {
    if (!key) throw Error('No key found for structure entry');
    const deepValue = key.split('.').reduce((acc, layer) => {
      return acc[layer];
    }, rowData);
    return mutation ? mutation(deepValue) : deepValue;
  }
  return (
    <Flex alss>
      <Flex alss style={{ width: 2, backgroundColor: is_selected ? colors.secondary : colors.mid, marginTop: 2, marginBottom: 2 }} />
      <ListItem disablePadding style={{ ...styles.row }}>
        <ListItemButton 
          disableTouchRipple 
          onClick={() => handleSelection ? handleSelection(data) : null} 
          sx={{
            cursor: handleSelection ? 'pointer' : 'default',
            '&:hover': { backgroundColor: handleSelection ? colors.light : null }, 
            paddingLeft: 1
          }}>
          {structure.map(({ key, style, mutation, hide }, i) => {
            if (hide) return null;
            const value = resolveRowValue(data, key, mutation);
            return (
              <Flex key={i} style={{ color: is_selected ? colors.secondary : null, ...style }}>
                {value || <span style={styles.emptyState}>None</span>}
              </Flex>
            )
          })}
        </ListItemButton>
      </ListItem>
    </Flex>
  );
}

const styles = {
  content: { 
    margin: 20,
    backgroundColor: colors.dark
  },
  row: { 
    alignSelf: 'stretch',
    fontSize: 14,
    borderBottom: `1px solid ${colors.mid}`
  },
  emptyState: { 
    color: colors.offwhite,
    opacity: .4, 
    fontStyle: 'italic'
  }
}