import { useEffect, useState } from 'react'
import Login from './Login.js'
import authAdmin from '../api.js';
import * as sessions from '../sessions.js';
import ResetPassword from './ResetPassword.js';
import colors from '../colors.js';
import AdminPortal from './AdminPortal.js';

function App() {
  const [isLoggedIn, toggleLoginState] = useState(false);
  const [isLoginError, toggleLoginError] = useState(false);
  const [session, setSession] = useState({});
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    (async function () {
      const sessionRes = sessions.getSession();
      if (!sessionRes.user_identifier || !sessionRes.portal) return logout();
      setSession(sessionRes);
      toggleLoginState(true);
    }());
  }, []);

  function resetLoginError() {
    toggleLoginError(false)
  }

  async function logout() {
    sessions.closeSession();
    setSession({});
    toggleLoginState(false);
  }
  
  async function login({ email, password }) {
    try {
      await authAdmin.login({ email, password });
      toggleLoginState(true);
      toggleLoginError(false);
      setSession(sessions.getSession());
    } catch(err) {
      console.log(err.message);
      toggleLoginError(true);
    }
  }

  function handleSetResetPassword(bool = false) {
    setResetPassword(bool);
  }

  if (resetPassword) return (
    <div style={styles.container}>
      <ResetPassword handleSetResetPassword={handleSetResetPassword} />
    </div>
  )

  return (
    <div style={styles.container}>
      {!isLoggedIn ? <Login
        session={session}
        login={login}
        isLoginError={isLoginError}
        isLoggedIn={isLoggedIn}
        resetLoginError={resetLoginError}
      /> : 
      <AdminPortal
        session={session}
        logout={logout} 
      />}
    </div>
  );
}

export default App;

const styles = {
  container: { 
    display: 'flex', 
    flexDirection: 'column', 
    flex: 1, 
    width: '100vw', 
    minHeight: '100vh', 
    backgroundColor: colors.dark
  }
}