export default {
  darkest: '#060C16',
  dark: '#0A0F1D',
  mid: '#111729',
  light: '#252F4B',
  lightest: '#38476D',
  white: '#e9eaf0',
  offwhite: '#727B8B',
  grey: '#626a78',
  secondary: '#F4CD72',
  red: '#fa5b50',
  green: '#49ad64',
  blue: '#3eacfa'
}