import { useState, useEffect, useRef } from "react";
import api from "../../../../../api.js";
import Flex from "../../../../global/Flex.js";
import colors from "../../../../../colors.js";
import TextField from '../../../../global/TextField.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { ClipLoader, BarLoader, BeatLoader } from 'react-spinners';
import { Button } from "@mui/material";


export default function({ selected: event, handleRetry }) {
  const [providerDataByProvider, setProviderDataByProvider] = useState();
  const [detailedEvent, setDetailedEvent] = useState(null);
  const refreshTimerRef = useRef();

  useEffect(() => {
    (async function() {
      await getPipelineEventDetails();
      if (event?.state === 'completed') return;
      const timer = setInterval(async () => await getPipelineEventDetails(), 1000);
      refreshTimerRef.current = timer;
    }());
  }, []);

  async function getPipelineEventDetails() {
    try {
      console.log('Fetching pipleine event details . . .');
      const eventDetailsResponse = await api.getPipelineEventById({ pipeline_event_id: event?.id });
      setDetailedEvent(eventDetailsResponse);
      const formattedProviderData = eventDetailsResponse?.provider_data.reduce((acc, { id, provider_id, provider_name, source_identifier, filename, type, state, state_previous, total_rows }) => {
        if (!acc[provider_name]) acc[provider_name] = {};
        if (!acc[provider_name][source_identifier]) acc[provider_name][source_identifier] = [];
        acc[provider_name][source_identifier].push({ 
          state,
          state_previous,
          state_icon: resolveProviderDataStateIcon(state), 
          provider_id,
          provider_data_id: id,
          filename,
          filenameFormatted: trimString(filename.split('/')[filename.split('/').length - 1]), 
          type,
          total_rows
        });
        return acc;
      }, {});
      setProviderDataByProvider(formattedProviderData);
      if (['completed', 'failed'].includes(eventDetailsResponse?.state)) clearInterval(refreshTimerRef.current);
    } catch(err) {
      clearInterval(refreshTimerRef.current);
      throw err;
    }
  }

  function resolveProviderDataStateIcon(state) {
    switch(state) {
      case 'completed': return <CheckCircleIcon style={{ color: colors.green }}/>;
      case 'failed': return <FmdBadIcon style={{ color: colors.secondary }}/>;
      case 'captured': 
      case 'collected': 
      case 'converted': 
      case 'inserted': return (
        <Flex style={{ transform: 'translateY(-2px)'}}><Flex>
          <ClipLoader speedMultiplier={1} color={colors.blue} size={18} /></Flex>
        </Flex>
      )
    }
  }

  function translateStateToCurrent(state) {
    switch(state) {
      case 'captured': return 'Collecting...';
      case 'collected': return 'Converting...';
      case 'converted': return 'Inserting...';
      case 'inserted': return 'Finalizing...';
    }
  }
  
  async function handleDownloadInternalReport({ provider_id, provider_name, provider_data_id, filename, type }) {
    const res = await api.getInternalDataReport({ provider_id, provider_name, provider_data_id, filename, type });
    let link = document.createElement('a');
    link.href = res;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function trimString(str) {
    if (str.length <= 50) return str;
    return str.slice(0, 50) + '...';
  }

  return (
    <Flex column f={1} alss>
      <Flex alss>
        <TextField disabled label='State' value={detailedEvent?.state} />
      </Flex>
      <Flex alss>
        <TextField disabled label='Created Date' value={detailedEvent?.created_date} />
      </Flex>
      <Flex alss>
        <TextField disabled label='Updated Date' value={detailedEvent?.updated_date} />
      </Flex>

      <Flex alss jcsb style={{ marginTop: 20 }}>
        <Flex style={{ fontSize: 16, fontStyle: 'italic', color: colors.secondary }}>Provider Data</Flex>
        {detailedEvent?.state === 'failed' && <Button 
          onClick={() => { handleRetry({ event_type: 'update', pipeline_event_id: event.id }) }}
          variant="contained"
          style={{ fontSize: 14, backgroundColor: colors.secondary, color: colors.darkest }}>
          {false ? <BeatLoader size={14} color="black" /> : <span>Retry</span>}
        </Button>}
      </Flex>
      <Flex f={1} alss style={{ backgroundColor: colors.darkest, padding: 10 }} column>
        {Object.entries(providerDataByProvider || {}).map(([provider_name, providerDataBySourceIdentifier ], i) => (
          <Flex key={i} column style={{ marginTop: 20 }}>
            <div style={styles.headerFont}>{provider_name}</div>
            <Flex column style={{ paddingLeft: 10 }}>
              <Flex style={styles.verticalLine}/>
              <Flex>
                <Flex column>
                  <Flex style={{ ...styles.verticalLine, height: 10 }}/>
                  <Flex style={styles.horizontalLine}/>
                </Flex>
                <Flex column>
                  {Object.entries(providerDataBySourceIdentifier).map(([source_identifier, providerDatas], j) => (
                    <Flex key={j} column>
                      {j > 0 && <Flex style={{ height: 20, width: 2, backgroundColor: colors.grey, marginLeft: 10 }} />}
                      <div style={styles.headerFont}>{source_identifier}</div>
                      {providerDatas.map(({ provider_id, provider_data_id, state, state_previous, filename, filenameFormatted, type }, k) => {
                        return (
                          <Flex key={k} column style={{ paddingLeft: 10 }}>
                            <Flex style={styles.verticalLine}/>
                            <Flex aife>
                              <Flex column>
                                <Flex style={{ ...styles.verticalLine, height: 30 }}/>
                                <Flex style={styles.horizontalLine}/>
                              </Flex>
                              <Flex column style={{ position: 'relative' }}>
                                {!['completed'].includes(state) && <StateStepper state={state} state_previous={state_previous} />}
                                <Flex jcsb style={{ transform: 'translateY(14px)'}}>
                                  <Flex aic>
                                    <span>{resolveProviderDataStateIcon(state)}</span>
                                    <span style={{ fontSize: 16, transform: 'translateY(-2px)', paddingLeft: 4 }}>{filenameFormatted}</span>
                                    <span style={{ color: state === 'failed' ? colors.secondary : colors.blue, paddingLeft: 20 }}>{translateStateToCurrent(state)}</span>
                                  </Flex>
                                  <SimCardDownloadIcon 
                                    onClick={() => handleDownloadInternalReport({ provider_id, provider_name, provider_data_id, filename, type })} 
                                    style={{ color: colors.secondary, fontSize: 24, cursor: 'pointer' }}
                                  />
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}


function StateStepper({ state, state_previous }) {
  const stateActual = state === 'failed' ? state_previous : state;
  const statePositions = ['captured', 'collected', 'converted', 'ingested', 'completed'];

  function renderLine(position, state, statePrevious) {
    const realState = state === 'failed' ? statePrevious : state;
    const stateIndex = statePositions.indexOf(realState);
    const positionIndex = statePositions.indexOf(position);
    if (state === 'failed') {
      if (stateIndex + 1 < positionIndex) return <Flex style={styles.lineInactive} />
      if (stateIndex + 1 === positionIndex) return< Flex style={styles.lineFailed} />
      if (stateIndex + 1 >= positionIndex) return <Flex style={styles.lineCompleted} />
    }
    if (stateIndex + 1 < positionIndex) return <Flex style={styles.lineInactive} />
    if (stateIndex + 1 === positionIndex) return <BarLoader speedMultiplier={1} color={colors.blue} size={10} />
    if (stateIndex + 1 >= positionIndex) return <Flex style={styles.lineCompleted} />
  }

  function renderCircle(position, state, statePrevious) {
    const realState = state === 'failed' ? statePrevious : state;
    const stateIndex = statePositions.indexOf(realState);
    const positionIndex = statePositions.indexOf(position);
    if (state === 'failed') {
      if (stateIndex + 1 < positionIndex) return <Flex style={styles.circleInactive} />
      if (stateIndex + 1 === positionIndex) return <CancelSharpIcon style={{ color: colors.secondary, fontSize: 16 }} />
      if (stateIndex + 1 >= positionIndex) return <Flex style={styles.circleCompleted} />
    }
    if (stateIndex + 1 < positionIndex) return <Flex style={styles.circleInactive} />
    if (stateIndex + 1 === positionIndex) return <Flex style={styles.circleActive} />
    if (stateIndex + 1 >= positionIndex) return <Flex style={styles.circleCompleted} />
  }

  return (
    <Flex aic style={{ position: 'absolute', bottom: 20, left: 5 }}>

      {renderCircle('captured', state, stateActual)}
      {renderLine('collected', state, stateActual)}

      {renderCircle('collected', state, stateActual)}
      {renderLine('converted', state, stateActual)}

      {renderCircle('converted', state, stateActual)}
      {renderLine('ingested', state, stateActual)}

      {renderCircle('ingested', state, stateActual)}
      {renderLine('completed', state, stateActual)}

      {renderCircle('completed', state, stateActual)}
    </Flex>
  )
}

const baseCircle = {
  width: 12, 
  height: 12, 
  borderRadius: 100
}
const baseLine = {
  height: 4, 
  width: 100, 
}

const styles = {
  headerFont: { 
    color: colors.grey, 
    fontSize: 16, 
    fontWeight: 'bold', 
    fontStyle: 'italic'
  },
  verticalLine: {
    backgroundColor: colors.grey, 
    height: 10, 
    width: 2
  },
  horizontalLine: {
    marginRight: 2,
    backgroundColor: colors.grey, 
    height: 2, 
    width: 10
  },
  lineFailed: { ...baseLine, backgroundColor: colors.secondary },
  lineInactive: { ...baseLine, backgroundColor: colors.mid },
  lineCompleted: { ...baseLine, backgroundColor: colors.green },
  circleInactive: { ...baseCircle, backgroundColor: colors.mid },
  circleActive: { ...baseCircle, backgroundColor: colors.blue },
  circleCompleted: { ...baseCircle, backgroundColor: colors.green },
}