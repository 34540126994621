import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import colors from '../../colors.js';
import Flex from "../global/Flex.js";
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton, Typography } from '@mui/material';
import navigation from './index.js';

export default function({ dimensions, page_id, handleSetPage }) {
  useEffect(() => {
    const initial = navigation[0].children[0]; // organizations
    handleSetPage({ id: initial.id, content: initial.content  });
  }, []);
  return (
    <Flex column alss style={{ width: dimensions.width_navigation, ...styles.container }}>
      <Flex f={1} alss column style={{ marginTop: 10 }}>
        <List style={{ alignSelf: 'stretch'}}>
          {navigation?.map(({ section, children }, i) => (
            <Flex column alss key={i}>
              <div style={{ color: colors.offwhite, fontSize: 12, fontStyle: 'italic', padding: 10 }}>{section}</div>
              {children?.map(({ icon, text, id, content }, i) => (
                <ListItem key={i} disablePadding style={{ alignSelf: 'stretch', backgroundColor: id === page_id ? colors.secondary : null }}>
                  <Link to={`/${id}`} style={{ flex: 1, alignSelf: 'stretch' }}>
                    <ListItemButton onClick={() => handleSetPage({ id, content })} sx={{ '&:hover': { backgroundColor: id !== page_id ? colors.light : null }, height: 30 }}>
                      <ListItemIcon style={{ marginRight: -20, opacity: .8 }}>{icon(id === page_id ? colors.darkest : colors.white)}</ListItemIcon>
                      <ListItemText primary={<Typography style={{ fontSize: 12, fontWeight: 'bold', color: id === page_id ? colors.darkest : colors.white, opacity: .8 }}>{text}</Typography>} style={{ fontSize: 10 }} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </Flex>
          ))}
        </List>
      </Flex>
    </Flex>
  );
} 

const styles = {
  container: {
    backgroundColor: colors.darkest
  },
  icons: {
    color: colors.offwhite,
    fontSize: '500px'
  }
}
