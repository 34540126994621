import { useEffect, useState } from "react";
import Flex from "../../../global/Flex";
import api from "../../../../api";
import Table from "../../../global/Table";
import colors from "../../../../colors";


export default function() {
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState(null);
  const [overview, setOverview] = useState(null);

  useEffect(() => {
    (async function() {
      const providers = await api.getProviders();
      setProviders(providers);
      setProvider(providers[0]);
      const overviewData = await api.getDataOverview({ provider_id: providers[0].id });
      setOverview(overviewData);
    }());
  }, []);

  return (
    <Flex column f={1} alss>
      <Table 
        title={`Encounter Counts`}
        data={overview}
        structure={[
          { column: 'Specialty', key: 'service_line', style: { minWidth: 150 } },
          { column: '# Filtered', key: 'counts.filtered',  style: { minWidth: 150 } },
          { column: '# Unfiltered', key: 'counts.unfiltered',  style: { minWidth: 150 } },
        ]}
      />
    </Flex>
  )
}
