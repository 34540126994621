import { useEffect, useState, useRef } from "react";
import moment from "moment";
import Flex from "../../../global/Flex.js";
import api from "../../../../api.js";
import PaginatedTable from '../../../global/PaginatedTable.js';
import Drawer from "../../Drawer.js";
import colors from "../../../../colors.js";
import DrawerHandleEvent from './drawers/HandleEvent.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import UpdateIcon from '@mui/icons-material/Update';
import { PacmanLoader } from 'react-spinners';
import { Button, Modal, Box, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material/';
import { BeatLoader } from 'react-spinners';


export default function() {
  const [stagedProviderData, setStagedProviderData] = useState([]);
  const [pipelineEvents, setPipelineEvents] = useState([]);

  const [loadingCheckFiles, setLoadingCheckFiles] = useState(true);
  const [loadingPipelineRun, setLoadingPipelineRun] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [runPipelinePopup, setRunPipelinePopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);

  const [drawerOpen, setDrawer] = useState('closed');
  const [selected, setSelected] = useState(null);
  const [enableRunPipeline, setEnableRunPipeline] = useState(false);
  const refreshTimerRef = useRef();

  useEffect(() => {
    (async function() {
      const stagedProviderDataRes = await api.getStagedProviderData();
      setStagedProviderData(stagedProviderDataRes);
      setLoadingCheckFiles(false);
      const data = await fetchData();
      const allComplete = !data.some(d => d.state === 'active');
      if (allComplete && stagedProviderDataRes.length > 0) setEnableRunPipeline(true);
      if (!loadingPipelineRun && (allComplete || data.length === 0)) return;
      startPollingEvents();
    }());
  }, []);

  useEffect(() => {
    if (!drawerOpen) startPollingEvents();
  }, [drawerOpen]);

  function startPollingEvents() {
    handleClearInterval();
    console.log('Starting interval');
    const timer = setInterval(async () => await fetchData(), 10000);
    refreshTimerRef.current = timer;
  }

  async function fetchData() {
    console.log('Polling events . . .');
    try {
      setLoadingPipelineRun(true);
      const data = (await api.getPipelineEvents()).map(res => ({ ...res, state_icon: resolveStateIcon(res.state) }));
      setSelected(data);
      setPipelineEvents(data);
      const allComplete = !data.some(d => d.state === 'active');
      if (allComplete || data.length === 0) setLoadingPipelineRun(false);
      if (!loadingPipelineRun && (allComplete || data.length === 0)) {
        handleClearInterval(refreshTimerRef.current);
      }
      return data;
    } catch(err) {
      handleClearInterval(refreshTimerRef.current);
      throw err;
    }
  }

  async function handleStartIntake() {
    setLoadingPipelineRun(true);
    setRunPipelinePopup(false);
    setLoadingCheckFiles(false);
    setEnableRunPipeline(false);
    await api.startIntake();
    startPollingEvents();
  }

  async function handleStartUpdate({ provider_name, source_identifier, filenames, pipeline_event_id }) {
    setLoadingUpdate(true);
    setUpdatePopup(false);
    api.startDataUpdate({ provider_name, source_identifier, filenames, pipeline_event_id });
    startPollingEvents();
  }

  async function handleRetry({ event_type, provider_name, source_identifier, filenames, pipeline_event_id }) {
    if (event_type === 'update') return handleStartUpdate({ provider_name, source_identifier, filenames, pipeline_event_id });
    if (event_type === 'intake') return handleStartIntake();
    else throw Error('No event type found');
  }

  function handleClearInterval(ref) {
    console.log('Clearing Event polling');
    clearInterval(ref);
  }

  async function handleSelection(selected) {
    const completeData = await api.getPipelineEventById({ pipeline_event_id: selected.id });
    setSelected(completeData);
    setDrawer('open');
  }

  function resolveStateIcon(state) {
    switch(state) {
      case 'completed': return <CheckCircleIcon style={{ color: colors.green }}/>;
      case 'failed': return <FmdBadIcon style={{ color: colors.secondary }}/>;
      case 'active': return <Flex style={{ transform: 'translate(2px, -5px)', paddingTop: 10, paddingBottom: 10 }}><PacmanLoader speedMultiplier={1} color={colors.blue} size={10} /></Flex>
    }
  }

  function handleCloseRunPipelineModal() {
    setRunPipelinePopup(false);
  }

  function handleCloseUpdateModal() {
    setUpdatePopup(false);
  }

  const failedEventExists = pipelineEvents.some(({ state }) => state === 'failed');
  const disableIntake = !enableRunPipeline || loadingPipelineRun || failedEventExists;
  const loadingIntake = loadingCheckFiles || loadingPipelineRun;
  const disableUpdate = loadingUpdate || loadingIntake || failedEventExists;

  return (
    <Flex f={1} column style={{ alignSelf: 'stretch' }}>
      <Drawer width={600} open={drawerOpen === 'open'} close={() => { setDrawer('closed'); setSelected(null);}}>
        {drawerOpen === 'open' && 
          <DrawerHandleEvent 
            selected={selected} 
            handleRetry={handleRetry}
          />
        }
      </Drawer>
      {/* BUTTON ROW */}
      <Flex alss jcsb aife>
        <Flex aic style={{ paddingLeft: 20, paddingTop: 20 }}>
          <Button 
            disabled={disableIntake} 
            onClick={() => { setRunPipelinePopup(true) }} 
            variant="contained" 
            style={{ 
              width: 160, height: 40, fontSize: 14,
              color: colors.darkest,
              backgroundColor: disableIntake ? colors.grey : colors.secondary,
            }}
          >
            {loadingIntake ? <BeatLoader size={14} color="black" /> : <span>Run Pipeline ({stagedProviderData.length})</span>}
          </Button>
          {failedEventExists && <span style={styles.subtext}>Unable to create new events until failed event is resolved</span>}
          {!failedEventExists && loadingCheckFiles ? <span style={styles.subtext}>Checking for files / decoding and relocating files</span> :
          !failedEventExists && loadingPipelineRun ? <span style={styles.subtext}>Converting and ingesting files</span> : null}
        </Flex>
        <Button 
          disabled={disableUpdate} 
          onClick={() => { setUpdatePopup(true) }} 
          variant="contained" 
          style={{ 
            width: 20, height: 40, fontSize: 14, 
            color: colors.darkest, 
            backgroundColor: disableUpdate ? colors.grey : colors.secondary
          }}>
          {loadingUpdate ? <BeatLoader size={14} color="black" /> : <span style={{ transform: 'translateY(3px)'}}><UpdateIcon style={{ fontSize: 30 }} /></span>}
        </Button>
      </Flex>
      {/* TABLE */}
      <PaginatedTable
        rows={pipelineEvents}
        columns={[
          { id: 'state_icon', label: 'State' },
          { id: 'type', label: 'Type' },
          { id: 'created_date', label: 'Started At' },
          { id: 'updated_date', label: 'Updated At' }
        ]}
        handleSelection={handleSelection}
      />
      {/* MODALS */}
      <RunPipelineModal 
        isOpen={runPipelinePopup} 
        stagedProviderData={stagedProviderData} 
        handleCloseRunPipelineModal={handleCloseRunPipelineModal}
        handleStartIntake={handleStartIntake}
        loadingPipelineRun={loadingPipelineRun}
      />
      <UpdateModal 
        isOpen={updatePopup} 
        handleCloseUpdateModal={handleCloseUpdateModal}
        handleStartUpdate={handleStartUpdate}
        loadingUpdate={loadingUpdate}
      />
    </Flex>
  );
}

function RunPipelineModal({ isOpen, stagedProviderData, handleCloseRunPipelineModal, handleStartIntake, loadingPipelineRun }) {
  return (
    <Modal
      open={isOpen}
      onClose={handleCloseRunPipelineModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 600,
        bgcolor: colors.mid,
        boxShadow: 24,
        display: 'flex',
        flex: 1
      }}>
          <Flex alss f={1} column style={{ padding: 20, fontSize: 18, color: colors.grey, fontStyle: 'italic' }}>
            <Flex alss jcsb>
              <div style={{ fontSize: 24, fontWeight: 'bold', color: colors.secondary }}>Run Pipeline</div>
              <Button onClick={() => {
                handleCloseRunPipelineModal();
              }}>Cancel</Button>
            </Flex>
            <Flex column f={1} alss>
              <div style={{ marginBottom: 20 }}>Staged Files:</div>
              {(stagedProviderData || []).map(({ provider_name, filename, identifier}, i) => (
                <Flex key={i} style={{ color: colors.white, fontSize: 18 }}>
                  {provider_name} - {identifier} - {filename.replace(`${identifier}/`, '')}
                </Flex>
              ))}
            </Flex>
            <Flex aic>
              <Button disabled={loadingPipelineRun} onClick={() => {handleStartIntake(true)}} variant="contained" style={{ fontSize: 14, backgroundColor: loadingPipelineRun ? colors.grey : colors.secondary, color: colors.darkest }}>
                {loadingPipelineRun ? <BeatLoader size={14} color="black" /> : <span>Start Intake</span>}
              </Button>
              <div style={{ fontStyle: 'italic', marginLeft: 20 }}>The above files will attempt ingestion</div>
            </Flex>
          </Flex>
      </Box>
    </Modal>
  );
}

function UpdateModal({ isOpen, loadingUpdate, handleCloseUpdateModal, handleStartUpdate }) {
  const [providerSelected, setProviderSelected] = useState('');
  const [sourceIdentifierSelected, setSourceIdentifierSelected] = useState('');
  const [providerDataSelected, setProviderDataSelected] = useState([]);
  const [details, setDetails] = useState({});
  const [providerDatas, setProviderDatas] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    (async function () {
      const detailsRes = await api.getPipelineDetails();
      setDetails(detailsRes);
    }());
  }, [isOpen]); 

  function handleChangeSelectedProviderData(selectedItems) {
    setProviderDataSelected(selectedItems);
  }

  async function handleSetSourceIdentifierSelected(identifier) {
    const files = await api.getProviderData({ provider_name: providerSelected, source_identifier: identifier });
    setProviderDatas(files);
    setSourceIdentifierSelected(identifier);
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseUpdateModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 600,
        bgcolor: colors.mid,
        boxShadow: 24,
        display: 'flex',
        flex: 1
      }}>
          <Flex alss f={1} column style={{ padding: 20, fontSize: 18, color: colors.grey, fontStyle: 'italic' }}>
            <Flex alss jcsb>
              <div style={{ fontSize: 24, fontWeight: 'bold', color: colors.secondary }}>Update Provider Data</div>
              <Button onClick={() => {
                setProviderDataSelected('');
                setSourceIdentifierSelected('');
                setProviderDataSelected([]);
                setDetails({});
                setProviderDatas([]);
                handleCloseUpdateModal();
              }}>Cancel</Button>
            </Flex>
            <Flex column f={1} alss>
              <div style={{ marginBottom: 20 }}>Select file(s) to update</div>
              <FormControl fullWidth>
                <InputLabel sx={{ color: colors.grey }}>Provider</InputLabel>
                <Select   
                  sx={styles.materialUISelect}
                  value={providerSelected} 
                  label="Provider" 
                  onChange={(e) => setProviderSelected(e.target.value)}
                >
                    {Object.keys(details).map((provider_name, i) => (
                      <MenuItem key={i} value={provider_name}>{provider_name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* BUFFER */} <div style={{ height: 20 }}/>
              {providerSelected && 
                <FormControl fullWidth>
                  <InputLabel sx={{ color: colors.grey }}>Integration</InputLabel>
                  <Select 
                    sx={styles.materialUISelect}
                    value={sourceIdentifierSelected} 
                    label="Provider" 
                    onChange={(e) => handleSetSourceIdentifierSelected(e.target.value)}
                  >
                    {(details[providerSelected] || []).map((identifier, i) => 
                      <MenuItem key={i} value={identifier}>{identifier}</MenuItem>
                    )}
                  </Select>
                </FormControl>}
              {/* BUFFER */} <div style={{ height: 20 }}/>
              {sourceIdentifierSelected && providerDatas.length > 0 &&
                <FormControl fullWidth>
                  <InputLabel sx={{ color: colors.grey }}>Files</InputLabel>
                  <Select 
                    multiple
                    value={providerDataSelected}
                    sx={styles.materialUISelect}
                    onChange={(event) => handleChangeSelectedProviderData(event.target.value)}
                    renderValue={(selected) => `Selected: (${selected.length})`}
                  >
                    {providerDatas.map(({ filename, updated_date }, i) => (
                      <MenuItem key={i} value={filename}>
                        <Checkbox checked={providerDataSelected.indexOf(filename) > -1} />
                        <Flex f={1} alss aic jcsb>
                          <ListItemText primary={(filename || '').replace(`${sourceIdentifierSelected}/`, '')} />
                          <span>{moment(updated_date).fromNow()}</span>
                        </Flex>
                      </MenuItem> 
                    ))}
                  </Select>
                </FormControl>}
              <Flex alss column style={{ marginTop: 20, color: colors.white}}>
                  {providerDataSelected.map(filename => (
                    <Flex alss jcsb>
                      <span>{(filename || '').replace(`${sourceIdentifierSelected}/`, '')}</span>
                      <span>{moment(providerDatas.find(({ filename: f }) => f === filename)?.updated_date).fromNow()}</span>
                    </Flex> 
                  ))}
              </Flex>

            </Flex>
            <Flex aic>
              <Button 
                disabled={providerDataSelected.length === 0 || loadingUpdate} 
                onClick={() => {handleStartUpdate({ provider_name: providerSelected, source_identifier: sourceIdentifierSelected, filenames: providerDataSelected })}} 
                variant="contained" 
                style={{ fontSize: 14, backgroundColor: providerDataSelected.length === 0 || loadingUpdate ? colors.grey : colors.secondary, color: colors.darkest }}>
                {loadingUpdate ? <BeatLoader size={14} color="black" /> : <span>Start Update</span>}
              </Button>
              {providerDataSelected.length > 0 && <div style={{ fontStyle: 'italic', marginLeft: 20 }}>The above files will attempt an update</div>}
            </Flex>
          </Flex>
      </Box>
    </Modal>
  );
}

const styles = {
  materialUISelect: {
    color: 'white',
    border: '1px solid #000', // Set your desired border style
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey, // Changes border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey, // Changes border color on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey, // Changes border color on focus
    },
    '& .MuiSvgIcon-root': {
      color: colors.grey, // Changes the dropdown arrow color
    }
  },
  subtext: { color: colors.grey, marginLeft: 10, fontStyle: 'italic' },
  // materialUIInputLabel: 
}